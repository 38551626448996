@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap";
:root {
  --black-color: #010000;
  --black-2: #333;
  --grey-color: #ddd;
  --grey-background: #faf7f7;
  --grey-text: #afafaf;
  --pink-color: #f0f;
  --pink2-color: #ea00c4;
  --electric-blue-1: #0ff;
  --violet-1: #7300ff;
  --violet-2: #5a12b3;
  --white-color: #fff;
  --font-size: 2rem;
  --width-size: 86%;
  --font-family: Montserrat, sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: Montserrat, sans-serif;
  font-size: 62.5%;
}

header {
  z-index: 1000;
  height: 10vh;
  position: relative;
}

.container {
  width: var(--width-size);
  margin: 0 auto;
}

.section {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.grey-background {
  background-color: var(--grey-background);
}

.section-small-pad {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}

.grey-bg {
  background-color: var(--grey-background);
}

.white-bg {
  background-color: var(--white-color);
}

.italic {
  font-style: italic;
}

h2 {
  color: var(--white-color);
  font-size: 7.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.h2-black {
  color: var(--black-color);
}

h3 {
  color: var(--black-color);
  text-align: center;
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h4 {
  color: var(--black-color);
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

h5 {
  color: var(--black-color);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
}

p {
  color: var(--black-color);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.weight-600 {
  font-weight: 600;
}

.underlined-pink-thin {
  font-weight: 600;
  display: inline-block;
}

.h3-span {
  color: var(--black-color);
  text-align: center;
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.underlined-pink-thin:after {
  content: "";
  background-color: var(--pink-color);
  height: .4rem;
  display: block;
}

.color-white {
  color: var(--white-color);
}

.capitalize {
  text-transform: capitalize;
}

.underlined-black-thin {
  display: inline-block;
}

.underlined-black-thin:after {
  content: "";
  background-color: var(--black-color);
  height: .4rem;
  display: block;
}

.underlined-black-thick {
  display: inline-block;
  position: relative;
}

.underlined-black-thick:after {
  content: "";
  background-color: var(--black-color);
  width: 100%;
  height: .8rem;
  display: block;
  position: absolute;
  bottom: -8%;
  left: 0;
}

.underlined-pink-thick {
  display: inline-block;
  position: relative;
}

.underlined-pink-thick:after {
  content: "";
  background-color: var(--pink-color);
  width: 100%;
  height: 1.2rem;
  display: block;
  position: absolute;
  bottom: -5%;
  left: 0;
}

.pink-thick-y-spacing:after {
  bottom: -15%;
}

.hidden {
  display: none;
}

.btn:link, .btn:visited {
  text-align: center;
  background: var(--black-color);
  color: var(--white-color);
  border-radius: 99.9rem;
  padding: 1.2rem 2.4rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.btn:hover, .btn:active {
  background-color: var(--black-2);
}

.btn {
  text-align: center;
  background: var(--black-color);
  color: var(--white-color);
  cursor: pointer;
  border: none;
  border-radius: 99.9rem;
  outline: none;
  width: 20rem;
  padding: 1.2rem 2.4rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.btn:hover {
  background-color: var(--black-2);
}

.btn:active, .btn:focus {
  background-color: var(--black-color);
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  background-color: var(--pink-color);
  transform-origin: 0 100%;
  width: 100%;
  height: 2px;
  transition: transform .25s cubic-bezier(.25, 0, 1, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.hover-underline-animation:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.no-scroll {
  overflow: hidden;
}

.gap-main-container {
  gap: 7.2rem;
}

.nav-container {
  z-index: 999;
  background-color: #ffffffad;
  place-content: center space-between;
  height: 9rem;
  padding-left: 9.6rem;
  padding-right: 9.6rem;
  transition: transform .4s ease-in-out;
  display: flex;
  transform: translateY(0);
}

.nav-links {
  justify-content: space-between;
  align-items: center;
  gap: 5.2rem;
  list-style: none;
  display: flex;
}

.logo-link {
  justify-content: center;
  align-items: center;
  display: flex;
}

.wellaware-logo {
  width: 9.8rem;
  height: 7.1rem;
}

.nav-link:link, .nav-link:visited {
  color: var(--black-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.nav-link:hover, .nav-link:active {
  color: var(--pink-color);
}

.subscribe-button {
  color: var(--violet-1);
  text-align: center;
  cursor: pointer;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.explore-link:link, .explore-link:visited, .explore-link:hover, .explore-link:active {
  background: var(--violet-1);
  color: var(--white-color);
  border-radius: .8rem;
  padding: 1.8rem 3.2rem;
}

.explore-link:hover, .explore-link:active {
  background: var(--violet-2);
  color: var(--white-color);
  border-radius: .8rem;
  padding: 1.8rem 3.2rem;
}

.btn-mobile-nav {
  cursor: pointer;
  z-index: 9999;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  color: var(--pink-color);
  width: 4.8rem;
  height: 4.8rem;
}

.close-mobile-nav {
  display: none;
}

.hidden-mobile {
  transform: translateY(-100%);
}

.hero-box {
  align-content: center;
  height: 90vh;
  display: flex;
}

.hero-container {
  place-content: center space-around;
  padding-top: 3.9rem;
  padding-bottom: 3.9rem;
  display: flex;
}

.hero-left-col {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3.2rem;
  width: 50%;
  padding-top: .8rem;
  padding-bottom: .8rem;
  display: flex;
}

h1 {
  color: var(--black-color);
  margin: 0;
  margin-block: 0;
  font-size: 7.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hero-title {
  margin: 0;
}

.hero-par {
  color: var(--black-color);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.1rem;
}

.btn-explore:link, .btn-explore:visited {
  background: var(--violet-1);
  color: var(--white-color);
  text-align: center;
  font-size: var(--font-size);
  border-radius: 1.6rem;
  width: 17.4rem;
  height: 7.2rem;
  padding: 2.4rem 4.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  display: inline-block;
}

.btn-explore:hover, .btn-explore:active {
  background: var(--violet-2);
  color: var(--white-color);
  text-align: center;
  font-size: var(--font-size);
  border-radius: 1.6rem;
  width: 17.4rem;
  padding: 2.4rem 4.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  display: inline-block;
}

.hero-img-container {
  width: 50%;
  overflow: hidden;
}

.gif {
  z-index: 1;
  width: 55%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10%, -50%);
}

.unlock-section {
  background-color: var(--violet-1);
}

.unlock-container {
  align-content: flex-start;
  gap: 6rem;
  display: flex;
}

.unlock-left-col {
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  width: 50%;
  display: flex;
}

.unlock-right-col {
  width: 50%;
}

.unlock-title {
  text-align: end;
}

.unlock-text {
  color: var(--white-color);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.unlock-highlight {
  font-weight: 600;
}

.video {
  width: 100%;
  height: 100%;
  display: block;
}

.journeys-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
  display: flex;
}

.journey-header {
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  display: flex;
}

.journey-text {
  text-align: center;
}

.journeys-sub-container {
  flex-direction: column;
  justify-content: center;
  gap: 10rem;
  display: flex;
}

.journey-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.6rem;
  display: flex;
}

.my-journey-img {
  width: 100%;
}

.your-journey-img {
  width: 50%;
}

.journey-wa-img {
  background-color: var(--pink-color);
  width: 62.1rem;
  height: 21.7rem;
}

.journey-msg {
  color: var(--black-color);
  text-align: center;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.h3-span {
  display: inline-block;
  position: relative;
}

.underlined-pink-thin-high:after {
  content: "";
  background-color: var(--pink-color);
  width: 100%;
  height: .4rem;
  display: block;
  position: absolute;
  bottom: -5%;
  left: 0;
}

.why-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.2rem;
  display: flex;
}

.why-grid {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  align-content: start;
  gap: 20px;
  width: 100%;
  height: auto;
  display: grid;
}

.flex-vert {
  flex-direction: column;
  display: flex;
}

.flex {
  display: flex;
}

.substack-link {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.article-container {
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
}

.article-text-container {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.article-img {
  object-fit: cover;
  border-radius: .4rem;
  max-width: 100%;
  height: 60%;
}

.article-text {
  -webkit-box-orient: vertical;
  font-size: 1.4rem;
  display: -webkit-box;
  overflow: hidden;
}

.main-article-grid {
  grid-row: 1 / 3;
  grid-column-start: 2;
}

.main-article-flex-container {
  gap: 4rem;
}

.main-article-title {
  text-align: center;
  font-size: 3rem;
}

.main-article-text {
  text-align: center;
  font-size: 1.8rem;
}

.main-article-date {
  text-align: center;
}

.article-date {
  color: #868787;
  font-size: 1.1rem;
}

.personas-sub-container {
  grid-column-gap: 2rem;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.personas-section {
  background-color: var(--violet-1);
}

.personas-container {
  flex-direction: column;
  align-items: center;
  gap: 7.2rem;
  display: flex;
}

.personas-title {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.persona-container {
  border: 1px solid var(--black-color);
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 1rem;
  display: flex;
}

.persona-comingsoon {
  font-size: 3.6rem;
}

.persona-text-cont {
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.persona-title {
  color: var(--grey-text);
  flex-shrink: 0;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  position: relative;
}

.persona-title:after {
  content: "";
  background-color: var(--pink-color);
  transform-origin: 0 100%;
  width: 100%;
  height: 2px;
  transition: transform .25s cubic-bezier(.25, 0, 1, 1);
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: scaleX(0);
}

.persona-box:hover .persona-title:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.persona-text {
  color: var(--grey-text);
  text-align: center;
  font-feature-settings: "liga" off;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.persona-img {
  border-radius: 4rem;
  max-width: 100%;
}

.lore-container {
  gap: 7.2rem;
}

.personas-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.personas-list {
  gap: .5rem;
  width: 50%;
  height: 100%;
}

.persona-img {
  border-radius: 4rem;
  width: 20rem;
  height: 20rem;
}

.persona-box {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

.persona-box.active {
  padding-left: 2rem;
}

.persona-box.active .persona-title, .persona-box.active .persona-text {
  color: #010000;
}

.persona-title-descr {
  align-items: flex-start;
  gap: 1rem;
}

.lore-text-box-desktop {
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.persona-lore-text-container {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

.persona-lore-text {
  font-size: 1.6rem;
}

.lore-link:link, .lore-link:visited {
  color: var(--pink-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
}

.lore-link:hover, .lore-link:active {
  color: var(--pink2-color);
}

.pursuit-section {
  justify-content: center;
  align-items: center;
  gap: 10rem;
  display: flex;
}

.pursuit-left-col {
  flex-direction: column;
  align-content: center;
  gap: 3.2rem;
  width: 70%;
  display: flex;
}

.pursuit-img {
  width: 30%;
}

.carousel-section {
  box-shadow: 0 4px 38px #0000000f;
}

.carousel-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.5rem;
  display: flex;
}

.carousel-sub-container {
  align-items: flex-start;
  gap: 5.2rem;
  display: flex;
}

.testimonial-container {
  justify-content: center;
  align-items: flex-end;
  width: 78.9rem;
  height: 20rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.testimonial {
  width: 100%;
  position: absolute;
  top: 0;
}

.testimonial-text {
  text-align: center;
}

.testimonial-bio {
  font-size: 1.6rem;
}

.testimonial-paragraph {
  margin-bottom: 1.6rem;
}

.btn-carousel {
  cursor: pointer;
  background-color: #fff;
  border: none;
  flex-shrink: 0;
  align-items: flex-start;
  width: 10.9rem;
  height: 10.9rem;
  display: flex;
}

.btn-carousel:hover svg path {
  fill: var(--pink-color);
}

.dots {
  gap: 12px;
  display: flex;
}

.dot {
  background-color: var(--grey-color);
  cursor: pointer;
  border-color: #0000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.dots__dot--active {
  background-color: var(--pink-color);
}

.subscribe-sub-section {
  flex-direction: column;
  place-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
}

.subscribe-title {
  color: var(--black-color);
  letter-spacing: -.01em;
  flex-shrink: 0;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subscribe-container {
  width: var(--width-size);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.4rem;
  margin: 3.2rem auto;
  display: flex;
}

.subscribe-text {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
  display: flex;
}

.subscribe-input:focus, .subscribe-input:active {
  border: 1px solid var(--black-color);
}

.subscribe-btn:link, .subscribe-btn:visited {
  text-align: center;
  background: var(--black-color);
  color: var(--white-color);
  border-radius: 99.9rem;
  padding: 1.2rem 2.4rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.subscribe-btn:hover, .subscribe-btn:active {
  background-color: var(--black-2);
}

footer {
  background-color: var(--black-color);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.footer-container {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: .8rem;
  display: grid;
}

.footer-rights {
  color: var(--white-color);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
}

.footer-social-links {
  justify-content: flex-start;
  justify-self: start;
  display: flex;
}

.footer-social-link {
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  display: flex;
}

.footer-social-link:hover svg path {
  fill: var(--pink-color);
}

.footer-links {
  justify-content: space-between;
  justify-self: end;
  align-items: center;
  gap: 5.2rem;
  list-style: none;
  display: flex;
}

.footer-link:link, .footer-link:visited {
  color: var(--white-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.footer-link:hover, .footer-link:active {
  color: var(--pink-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.explore-link:hover, .explore-link:active {
  color: var(--white-color);
}

.instagram-icon {
  width: 3rem;
  height: 3.1rem;
}

.pinterest-icon {
  width: 2.3rem;
  height: 3.1rem;
}

.linkedin-icon {
  width: 3.2rem;
  height: 3.1rem;
}

#nav-sentinel {
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav-container.sticky {
  z-index: 1000;
  background-color: #fffffff2;
  width: 100%;
  height: 9rem;
  padding-left: 9.6rem;
  padding-right: 9.6rem;
  position: fixed;
}

.lazy-img {
  filter: blur(1rem);
}

.success-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  height: 100vh;
  display: flex;
}

.btn-back:link, .btn-back:visited {
  background: var(--violet-1);
  color: var(--white-color);
  border-radius: 1.6rem;
  justify-content: center;
  align-items: center;
  width: 21.6rem;
  height: 7.2rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  display: flex;
}

.btn-back:hover, .btn-back:active {
  background: var(--violet-2);
}

.cookie-consent-banner {
  color: #000;
  text-align: center;
  z-index: 1000;
  opacity: .9;
  background-color: #f8f9fa;
  padding: 1.5rem;
  font-size: 1rem;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 10px #0000001a;
}

.cookie-title, .cookie-text {
  font-size: 1.6rem;
}

.cookie-consent-button {
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: .4rem;
  margin: .4rem .2rem;
  padding: .8rem 1.6rem;
  font-size: 1.4rem;
  text-decoration: none;
  display: inline-block;
}

.cookie-consent-button:hover {
  box-shadow: 0 -2px 5px #0003;
}

.cookie-consent-button:active {
  opacity: .5;
}

.cookie-consent-button.btn-success {
  background-color: var(--pink-color);
  color: var(--black-2);
}

.cookie-consent-button.btn-grayscale {
  color: #000;
  background-color: #dfe1e5;
}

.cookie-consent-button.btn-outline {
  color: #000;
  background-color: #ff00ff36;
}

.cookie-consent-options {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
}

.cookie-consent-options label {
  margin: 0 10px;
  font-size: 14px;
}

.cookie-consent-options input {
  margin-right: 5px;
}
/*# sourceMappingURL=index.3b347b72.css.map */
