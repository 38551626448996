@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

/* GLOBAL CONFIGURATION */

:root {
  --black-color: #010000;
  --black-2: #333;
  --grey-color: #ddd;
  --grey-background: #faf7f7;
  --grey-text: #afafaf;
  --pink-color: #f0f;
  --pink2-color: #ea00c4;
  --electric-blue-1: #0ff;
  --violet-1: #7300ff;
  --violet-2: #5a12b3;
  --white-color: #fff;
  --font-size: 2rem;
  --width-size: 86%;
  --font-family: Montserrat, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: Montserrat, sans-serif;
  font-size: 62.5%;
}

header {
  height: 10vh;
  z-index: 1000;

  position: relative;
}

/* RE-USABLE COMPONENTS */

.container {
  margin: 0 auto;
  width: var(--width-size);
}

.section {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.grey-background {
  background-color: var(--grey-background);
}

.section-small-pad {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}

.grey-bg {
  background-color: var(--grey-background);
}
.white-bg {
  background-color: var(--white-color);
}

.italic {
  font-style: italic;
}

h2 {
  color: var(--white-color);
  font-size: 7.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.h2-black {
  color: var(--black-color);
}

h3 {
  color: var(--black-color);
  text-align: center;
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h4 {
  color: var(--black-color);
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

h5 {
  color: var(--black-color);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  /* text-align: center; */
}

p {
  color: var(--black-color);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.weight-600 {
  font-weight: 600;
}

.underlined-pink-thin {
  display: inline-block;
  font-weight: 600;
}

/* .H3-SPAN HAS TO BE ORDERED AFTER .UNDERLINED-PINK-THIN */
.h3-span {
  color: var(--black-color);
  text-align: center;
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.underlined-pink-thin::after {
  content: '';
  display: block;
  height: 0.4rem;
  background-color: var(--pink-color);
}

.color-white {
  color: var(--white-color);
}

.capitalize {
  text-transform: capitalize;
}

.underlined-black-thin {
  display: inline-block;
}

.underlined-black-thin::after {
  content: '';
  display: block;
  height: 0.4rem;
  background-color: var(--black-color);
}

.underlined-black-thick {
  display: inline-block;
  position: relative;
}

.underlined-black-thick::after {
  content: '';
  display: block;
  height: 0.8rem;
  background-color: var(--black-color);

  position: absolute;
  left: 0;
  bottom: -8%;
  width: 100%;
}

.underlined-pink-thick {
  display: inline-block;
  position: relative;
}

.underlined-pink-thick::after {
  content: '';
  display: block;
  height: 1.2rem;
  background-color: var(--pink-color);

  position: absolute;
  left: 0;
  bottom: -5%;
  width: 100%;
}

.pink-thick-y-spacing::after {
  bottom: -15%;
}

.hidden {
  display: none;
}

/* Code for links acting as buttons */
.btn:link,
.btn:visited {
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 1.2rem 2.4rem;
  border-radius: 99.9rem;
  background: var(--black-color);
  color: var(--white-color);
}

.btn:hover,
.btn:active {
  background-color: var(--black-2);
}

/* Code for real buttons */
.btn {
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 1.2rem 2.4rem;
  border-radius: 99.9rem;
  background: var(--black-color);
  color: var(--white-color);
  border: none;
  cursor: pointer;
  /* Optional: Remove default button styling */
  outline: none;
  width: 20rem;
}

.btn:hover {
  background-color: var(--black-2);
}

/* Ensure active and focus states remain the same as the initial style */
.btn:active,
.btn:focus {
  background-color: var(--black-color);
}

/* Underline animation */
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--pink-color);
  transform-origin: bottom left;
  /* transition: transform 0.5s ease-in; */
  transition: transform 0.25s cubic-bezier(0.25, 0, 1, 1);
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.no-scroll {
  overflow: hidden;
}

.gap-main-container {
  gap: 7.2rem;
}

/* NAVIGATION */

.nav-container {
  height: 9rem;
  padding-right: 9.6rem;
  padding-left: 9.6rem;
  background-color: rgba(255, 255, 255, 0.68);

  z-index: 999;

  display: flex;
  justify-content: space-between;
  align-content: center;

  transform: translateY(0);
  transition: transform 0.4s ease-in-out;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5.2rem;
  list-style: none;
}

.logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wellaware-logo {
  width: 9.8rem;
  height: 7.1rem;
}

.nav-link:link,
.nav-link:visited {
  color: var(--black-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.nav-link:hover,
.nav-link:active {
  color: var(--pink-color);
}

.subscribe-button {
  color: var(--violet-1);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;

  cursor: pointer;
}

.explore-link:link,
.explore-link:visited,
.explore-link:hover,
.explore-link:active {
  padding: 1.8rem 3.2rem;
  border-radius: 0.8rem;
  background: var(--violet-1);
  color: var(--white-color);
}

.explore-link:hover,
.explore-link:active {
  padding: 1.8rem 3.2rem;
  border-radius: 0.8rem;
  background: var(--violet-2);
  color: var(--white-color);
}

/* MOBILE */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
  z-index: 9999;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: var(--pink-color);
}

.close-mobile-nav {
  display: none;
}

.hidden-mobile {
  transform: translateY(-100%);
}

/* HERO SECTION */

.hero-box {
  height: 90vh;
  display: flex;
  align-content: center;
}

.hero-container {
  padding-top: 3.9rem;
  padding-bottom: 3.9rem;

  display: flex;
  align-content: center;
  justify-content: space-around;
}

.hero-left-col {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  gap: 3.2rem;
  align-items: flex-start;
  justify-content: center;

  z-index: 2;
}

h1 {
  color: var(--black-color);
  font-size: 7.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.hero-title {
  margin: 0;
}

.hero-par {
  color: var(--black-color);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.1rem;
}

.btn-explore:link,
.btn-explore:visited {
  display: inline-block;
  width: 17.4rem;
  height: 7.2rem;
  padding: 2.4rem 4.8rem;
  border-radius: 1.6rem;
  background: var(--violet-1);

  color: var(--white-color);
  text-align: center;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.btn-explore:hover,
.btn-explore:active {
  display: inline-block;
  width: 17.4rem;
  padding: 2.4rem 4.8rem;
  border-radius: 1.6rem;
  background: var(--violet-2);

  color: var(--white-color);
  text-align: center;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.hero-img-container {
  width: 50%;
  overflow: hidden;
}

.gif {
  width: 55%;
  height: 100%;
  display: block;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10%, -50%);

  z-index: 1;
  /* background-color: #0ff; */
}

/* UNLOCK SECTION */

.unlock-section {
  background-color: var(--violet-1);
}

.unlock-container {
  display: flex;
  align-content: flex-start;
  gap: 6rem;
}

.unlock-left-col {
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 3rem;
}

.unlock-right-col {
  width: 50%;
}

.unlock-title {
  text-align: end;
}

.unlock-text {
  color: var(--white-color);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.unlock-highlight {
  font-weight: 600;
}

/* VIDEO */

.video {
  display: block;
  width: 100%;
  height: 100%;
}

/* JOURNEY SECTION */

.journeys-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
}

.journey-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}

.journey-text {
  text-align: center;
}

.journeys-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10rem;
}

.journey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5.6rem;
}

.my-journey-img {
  width: 100%;
}

.your-journey-img {
  width: 50%;
}

.journey-wa-img {
  width: 62.1rem;
  height: 21.7rem;
  background-color: var(--pink-color);
}

.journey-msg {
  color: var(--black-color);
  text-align: center;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.h3-span {
  display: inline-block;
  position: relative;
}

.underlined-pink-thin-high::after {
  content: '';
  display: block;
  height: 0.4rem;
  background-color: var(--pink-color);

  position: absolute;
  left: 0;
  bottom: -5%;
  width: 100%;
}

/* MAGAZINE VIEW */

.why-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 7.2rem;
}

.why-grid {
  display: grid;

  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-content: start;
  gap: 20px;
  width: 100%; /* Ensure the grid takes up full width */
  height: auto; /* Ensure the grid takes up the required height */
}

.flex-vert {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
}

.substack-link {
  text-decoration: none;
  height: 100%;
  width: 100%;
}

.article-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1rem;
  height: 100%;
  width: 100%;

  justify-content: start; /* Center content vertically */

  /* background-color: #0ff; */
}

.article-text-container {
  display: flex;

  flex-direction: column;
  gap: 1rem;
}

.article-img {
  border-radius: 0.4rem;
  height: 60%;
  max-width: 100%;
  object-fit: cover;
}

.article-text {
  font-size: 1.4rem;

  /* background-color: #5a12b3; */

  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  overflow: hidden;
}

.main-article-grid {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
}

.main-article-flex-container {
  gap: 4rem;
}

.main-article-title {
  font-size: 3rem;
  text-align: center;
}

.main-article-text {
  font-size: 1.8rem;
  text-align: center;
}

.main-article-date {
  text-align: center;
}

.article-date {
  font-size: 1.1rem;
  color: #868787;
}

/* PERSONAS SECTION */

.personas-sub-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 2rem;
}

.personas-section {
  background-color: var(--violet-1);
}

.personas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7.2rem;
}

.personas-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 2rem;
}

.persona-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4rem;

  border: 1px solid var(--black-color);
  padding: 1rem;
}

.persona-comingsoon {
  font-size: 3.6rem;
}

.persona-text-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.persona-title {
  color: var(--grey-text);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-shrink: 0;

  /* animation */
  display: inline-block;
  position: relative;
}

.persona-title::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--pink-color);
  transform-origin: bottom left;
  /* transition: transform 0.5s ease-in; */
  transition: transform 0.25s cubic-bezier(0.25, 0, 1, 1);
}

.persona-box:hover .persona-title::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.persona-text {
  color: var(--grey-text);
  text-align: center;
  font-feature-settings: 'liga' off;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.persona-img {
  max-width: 100%;
  border-radius: 4rem;
}

/* NEW section */

.lore-container {
  gap: 7.2rem;
}

.personas-container {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
}

.personas-list {
  width: 50%;
  height: 100%;

  gap: 0.5rem;
}

.persona-img {
  width: 20rem;
  height: 20rem;
  border-radius: 4rem;
}

.persona-box {
  /* height: 14rem; */
  padding-bottom: 2rem;
  /* padding-right: 3rem; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  /* background-color: #dfe1e5; */

  cursor: pointer;
}

.persona-box.active {
  padding-left: 2rem;
}
.persona-box.active .persona-title {
  color: #010000;
}
.persona-box.active .persona-text {
  color: #010000;
}

.persona-title-descr {
  align-items: flex-start;
  gap: 1rem;
}

.lore-text-box-desktop {
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.persona-lore-text-container {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.persona-lore-text {
  font-size: 1.6rem;
}

.lore-link:link,
.lore-link:visited {
  color: var(--pink-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
}

.lore-link:hover,
.lore-link:active {
  color: var(--pink2-color);
}

/* INTELLECTUAL PURSUIT SECTION */

.pursuit-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
}

.pursuit-left-col {
  width: 70%;

  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 3.2rem;
}

.pursuit-img {
  width: 30%;
}

/* WHAT OUR READERS SAY SECTION */

.carousel-section {
  box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.06);
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5.5rem;
}

.carousel-sub-container {
  display: flex;
  align-items: flex-start;

  gap: 5.2rem;
}

.testimonial-container {
  width: 78.9rem;
  height: 20rem;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  overflow: hidden;

  position: relative;
}

.testimonial {
  position: absolute;
  top: 0;
  width: 100%;
}

.testimonial-text {
  text-align: center;
}

.testimonial-bio {
  font-size: 1.6rem;
}

.testimonial-paragraph {
  margin-bottom: 1.6rem;
}

.btn-carousel {
  background-color: #fff;
  border: none;
  cursor: pointer;
  width: 10.9rem;
  height: 10.9rem;

  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}

.btn-carousel:hover svg path {
  fill: var(--pink-color);
}

.dots {
  display: flex;
  gap: 12px;
}

.dot {
  height: 16px;
  width: 16px;
  background-color: var(--grey-color);
  border-color: transparent;

  border-radius: 50%;
  cursor: pointer;
}

.dots__dot--active {
  background-color: var(--pink-color);
}

/* STAY UPDATED SECTION */

.subscribe-sub-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  /* background-color: var(--white-color); */
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.subscribe-title {
  line-height: 120%;

  color: var(--black-color);
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-shrink: 0;
  letter-spacing: -0.01em;
}

.subscribe-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2.4rem;

  width: var(--width-size);
  margin: 3.2rem auto;
}

.subscribe-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  gap: 3.2rem;
}

.subscribe-input:focus,
.subscribe-input:active {
  border: 1px solid var(--black-color);
}

.subscribe-btn:link,
.subscribe-btn:visited {
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 1.2rem 2.4rem;
  border-radius: 99.9rem;
  background: var(--black-color);
  color: var(--white-color);
}

.subscribe-btn:hover,
.subscribe-btn:active {
  background-color: var(--black-2);
}

/* FOOTER */

footer {
  background-color: var(--black-color);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  row-gap: 0.8rem;
}

.footer-rights {
  color: var(--white-color);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
}

.footer-social-links {
  display: flex;
  justify-content: flex-start;

  justify-self: start;
}

.footer-social-link {
  background-color: transparent;
  width: 5rem;
  height: 5rem;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-link:hover svg path {
  fill: var(--pink-color);
}

/* Links can be refactored with nav links */

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5.2rem;
  list-style: none;

  justify-self: end;
}

.footer-link:link,
.footer-link:visited {
  color: var(--white-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.footer-link:hover,
.footer-link:active {
  color: var(--pink-color);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.explore-link:hover,
.explore-link:active {
  color: var(--white-color);
}

.instagram-icon {
  width: 3rem;
  height: 3.1rem;
}
/* .tiktok-icon {
  width: 2.6rem;
  height: 3.1rem;
} */

.pinterest-icon {
  width: 2.3rem;
  height: 3.1rem;
}
.linkedin-icon {
  width: 3.2rem;
  height: 3.1rem;
}

/* STICKY NAVIGATION */

#nav-sentinel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
}

.nav-container.sticky {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  width: 100%;
  padding-right: 9.6rem;
  padding-left: 9.6rem;

  height: 9rem;
}

/* LAZY IMAGES */
.lazy-img {
  filter: blur(1rem);
}

/* SUCCESSFUL SUBSCRIPTION PAGE */

.success-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
}

.btn-back:link,
.btn-back:visited {
  display: inline-block;
  width: 21.6rem;
  height: 7.2rem;
  border-radius: 1.6rem;
  background: var(--violet-1);

  color: var(--white-color);
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-back:hover,
.btn-back:active {
  background: var(--violet-2);
}

/* Google analytics banner */
.cookie-consent-banner {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  color: black;
  padding: 1.5rem;
  font-size: 1rem;
  text-align: center;
  z-index: 1000;
  opacity: 0.9;
}

.cookie-title {
  font-size: 1.6rem;
}
.cookie-text {
  font-size: 1.6rem;
}

.cookie-consent-button {
  border: none;
  padding: 0.8rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.4rem;
  margin: 0.4rem 0.2rem;
  cursor: pointer;
  border-radius: 0.4rem;
}

.cookie-consent-button:hover {
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.cookie-consent-button:active {
  opacity: 0.5;
}

.cookie-consent-button.btn-success {
  background-color: var(--pink-color);
  color: var(--black-2);
}

.cookie-consent-button.btn-grayscale {
  background-color: #dfe1e5;
  color: black;
}

.cookie-consent-button.btn-outline {
  background-color: rgba(255, 0, 255, 0.21);
  color: black;
}

.cookie-consent-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.cookie-consent-options label {
  margin: 0 10px;
  font-size: 14px;
}

.cookie-consent-options input {
  margin-right: 5px;
}
